import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import {
  setAfishaSelections,
  setBookingRests,
  setFeatureRests,
  setOtherNews,
  setQuickFilters,
  setRatingRests,
  setRestaurantsNews,
  setSpecialRests,
  setTags,
  setWeekPlans,
} from '@pages/AppHome/model/slice'
import { splitEvenOdd } from '@pages/AppHome/model/helpers'

export const initialAppHomePageAC = (data) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setFeatureRests(data.feature_rests))
  dispatch(setQuickFilters(data.quick_filters))
  dispatch(setTags(data.tags))
  dispatch(setSpecialRests(data.special_offer))
  dispatch(setBookingRests(data.booking_rests))
  dispatch(setRatingRests(data.by_rating))
  dispatch(setAfishaSelections(data.afisha_selected))
  const { evenArray, oddArray } = splitEvenOdd(data.all_data)
  dispatch(setRestaurantsNews(evenArray))
  dispatch(setOtherNews(oddArray))
  dispatch(setWeekPlans(data.plani_na_nedelu))
}
